import Vue from 'vue'
import VueRouter from 'vue-router'

// 导入全局样式表
import '../assets/css/global.css'
// import Login from '../components/LoginVue.vue'
// import Home from '../components/HomeVue.vue'
// import Welcome from '../components/WelCome.vue'
const Login = () => import(/* webpackChunkName: "login_home_welcome" */'../components/LoginVue.vue')
const Home = () => import(/* webpackChunkName: "login_home_welcome" */'../components/HomeVue.vue')
const Welcome = () => import(/* webpackChunkName: "login_home_welcome" */'../components/WelCome.vue')

// import Users from '../components/user/UserVue.vue'
const Users = () => import(/* webpackChunkName: "Users_Rights_roles" */'../components/user/UserVue.vue')
// import Rights from '../components/power/RightsVue.vue'
const Rights = () => import(/* webpackChunkName: "Users_Rights_roles" */'../components/power/RightsVue.vue')

// import Roles from '../components/power/RolesVue.vue'
const Roles = () => import(/* webpackChunkName: "Users_Rights_roles" */'../components/power/RolesVue.vue')

// import Cate from '../components/goods/CateVue.vue'
const Cate = () => import(/* webpackChunkName: "Cate_Params" */'../components/goods/CateVue.vue')

// import Params from '../components/goods/ParamsVue.vue'
const Params = () => import(/* webpackChunkName: "Cate_Params" */'../components/goods/ParamsVue.vue')

// import GoodList from '../components/goods/ListVue.vue'
const GoodList = () => import(/* webpackChunkName: "GoodList_Add" */'../components/goods/ListVue.vue')

// import Add from '../components/goods/AddVue.vue'
const Add = () => import(/* webpackChunkName: "GoodList_Add" */'../components/goods/AddVue.vue')

// import Order from '../components/order/OrderVue.vue'
const Order = () => import(/* webpackChunkName: "Order_Report" */'../components/order/OrderVue.vue')

// import Report from '../components/report/ReportVue.vue'
const Report = () => import(/* webpackChunkName: "Order_Report" */'../components/report/ReportVue.vue')

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  {
    path: '/home',
    component: Home,
    redirect: '/welcome',
    children: [
      { path: '/welcome', component: Welcome },
      { path: '/users', component: Users },
      { path: '/rights', component: Rights },
      { path: '/roles', component: Roles },
      { path: '/categories', component: Cate },
      { path: '/params', component: Params },
      { path: '/goods', component: GoodList },
      { path: '/goods/add', component: Add },
      { path: '/orders', component: Order },
      { path: '/reports', component: Report }

    ]
  }

]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/login') return next()
  const tokenStr = window.sessionStorage.getItem('token')
  if (!tokenStr) return next('/login')
  next()
})

export default router
